<template>
  <article class="flex flex-col items-center">
    <p class="text-lg font-title">
      {{ t(`state.${currentPage.name}`, titleOptions) }}
    </p>
    <p class="text-gray-400">
      {{ t(`state.${currentPage.name}.subtitle`, subtitleOptions) }}
    </p>
  </article>
</template>
<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import { useState } from '@/composables/State';

  const { t } = useI18n();
  const { currentPage, subtitleOptions, titleOptions } = useState();
</script>
