export enum ApiSWRVKey {
  LIST_MISSIONS = 'LIST_MISSIONS',
  DETAILS_MISSION = 'DETAILS_MISSION',
  DETAILS_MISSION_PROGRESS = 'DETAILS_MISSION_PROGRESS',
  PLAYER_INFO = 'PLAYER_INFO',
  PLAYER_STATS = 'PLAYER_STATS',
  PLAYER_ACHIEVEMENTS = 'PLAYER_ACHIEVEMENTS',
  PLAYER_LEADER_BOARD = 'PLAYER_LEADER_BOARD',
  MISSIONS_STATS = 'MISSIONS_STATS',
  REPORT = 'REPORT',
  FEEDBACKS = 'FEEDBACKS',
  STATS_SUMMARY = 'STATS_SUMMARY',
  RESOURCES_VIDEO = 'RESOURCES_VIDEO'
}
