/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerLeaderboard
 */
export interface PlayerLeaderboard {
    /**
     * The player Id
     * @type {string}
     * @memberof PlayerLeaderboard
     */
    playerId: string;
    /**
     * The player callSign
     * @type {string}
     * @memberof PlayerLeaderboard
     */
    callSign: string;
    /**
     * The player rank
     * @type {number}
     * @memberof PlayerLeaderboard
     */
    rank: number;
    /**
     * The amount of rewards earned by the player
     * @type {number}
     * @memberof PlayerLeaderboard
     */
    rewardsEarned: number;
    /**
     * The number of missions failed by the player
     * @type {number}
     * @memberof PlayerLeaderboard
     */
    missionsFailed: number;
    /**
     * The bumber of missions completed by the player
     * @type {number}
     * @memberof PlayerLeaderboard
     */
    missionsCompleted: number;
    /**
     * An integer representing the total player playtime, in minutes.
     * @type {number}
     * @memberof PlayerLeaderboard
     */
    lifetimePlayTimeInMinutes: number;
}

export function PlayerLeaderboardFromJSON(json: any): PlayerLeaderboard {
    return PlayerLeaderboardFromJSONTyped(json, false);
}

export function PlayerLeaderboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerLeaderboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playerId': json['playerId'],
        'callSign': json['callSign'],
        'rank': json['rank'],
        'rewardsEarned': json['rewardsEarned'],
        'missionsFailed': json['missionsFailed'],
        'missionsCompleted': json['missionsCompleted'],
        'lifetimePlayTimeInMinutes': json['lifetimePlayTimeInMinutes'],
    };
}

export function PlayerLeaderboardToJSON(value?: PlayerLeaderboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playerId': value.playerId,
        'callSign': value.callSign,
        'rank': value.rank,
        'rewardsEarned': value.rewardsEarned,
        'missionsFailed': value.missionsFailed,
        'missionsCompleted': value.missionsCompleted,
        'lifetimePlayTimeInMinutes': value.lifetimePlayTimeInMinutes,
    };
}

