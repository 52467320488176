/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Connect,
    ConnectFromJSON,
    ConnectToJSON,
    FeedbacksList,
    FeedbacksListFromJSON,
    FeedbacksListToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineObject1,
    InlineObject1FromJSON,
    InlineObject1ToJSON,
    SendFeedback,
    SendFeedbackFromJSON,
    SendFeedbackToJSON,
} from '../models';

export interface ConnectPlayerRequest {
    inlineObject: InlineObject;
}

export interface SendFeedbackRequest {
    inlineObject1: InlineObject1;
}

/**
 * 
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     * Player connection method. Used to validate the player `access code`, retrieve the `JWT` token and basic player information.
     */
    async connectPlayerRaw(requestParameters: ConnectPlayerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connect>> {
        if (requestParameters.inlineObject === null || requestParameters.inlineObject === undefined) {
            throw new runtime.RequiredError('inlineObject','Required parameter requestParameters.inlineObject was null or undefined when calling connectPlayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/connect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectFromJSON(jsonValue));
    }

    /**
     * Player connection method. Used to validate the player `access code`, retrieve the `JWT` token and basic player information.
     */
    async connectPlayer(requestParameters: ConnectPlayerRequest, initOverrides?: RequestInit): Promise<Connect> {
        const response = await this.connectPlayerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all player feedbacks.
     */
    async getPlayerFeedbacksRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeedbacksList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbacksListFromJSON(jsonValue));
    }

    /**
     * Returns all player feedbacks.
     */
    async getPlayerFeedbacks(initOverrides?: RequestInit): Promise<FeedbacksList> {
        const response = await this.getPlayerFeedbacksRaw(initOverrides);
        return await response.value();
    }

    /**
     * Register a player feedback
     */
    async sendFeedbackRaw(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SendFeedback>> {
        if (requestParameters.inlineObject1 === null || requestParameters.inlineObject1 === undefined) {
            throw new runtime.RequiredError('inlineObject1','Required parameter requestParameters.inlineObject1 was null or undefined when calling sendFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.inlineObject1),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendFeedbackFromJSON(jsonValue));
    }

    /**
     * Register a player feedback
     */
    async sendFeedback(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit): Promise<SendFeedback> {
        const response = await this.sendFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
