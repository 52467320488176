/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerInformationPlayer,
    PlayerInformationPlayerFromJSON,
    PlayerInformationPlayerFromJSONTyped,
    PlayerInformationPlayerToJSON,
} from './PlayerInformationPlayer';

/**
 * 
 * @export
 * @interface PlayerInformation
 */
export interface PlayerInformation {
    /**
     * 
     * @type {PlayerInformationPlayer}
     * @memberof PlayerInformation
     */
    player: PlayerInformationPlayer;
}

export function PlayerInformationFromJSON(json: any): PlayerInformation {
    return PlayerInformationFromJSONTyped(json, false);
}

export function PlayerInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'player': PlayerInformationPlayerFromJSON(json['player']),
    };
}

export function PlayerInformationToJSON(value?: PlayerInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': PlayerInformationPlayerToJSON(value.player),
    };
}

