/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerCreateRequester
 */
export interface PlayerCreateRequester {
    /**
     * The Slack requester name
     * @type {string}
     * @memberof PlayerCreateRequester
     */
    name?: string;
    /**
     * The Slack requester id
     * @type {string}
     * @memberof PlayerCreateRequester
     */
    id?: string;
}

export function PlayerCreateRequesterFromJSON(json: any): PlayerCreateRequester {
    return PlayerCreateRequesterFromJSONTyped(json, false);
}

export function PlayerCreateRequesterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerCreateRequester {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function PlayerCreateRequesterToJSON(value?: PlayerCreateRequester | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
    };
}

