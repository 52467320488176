/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageToJSON,
    PlayerLeaderboardList,
    PlayerLeaderboardListFromJSON,
    PlayerLeaderboardListToJSON,
    PlayerMissionsHistory,
    PlayerMissionsHistoryFromJSON,
    PlayerMissionsHistoryToJSON,
    PlayerStats,
    PlayerStatsFromJSON,
    PlayerStatsToJSON,
    StatsAchievements,
    StatsAchievementsFromJSON,
    StatsAchievementsToJSON,
} from '../models';

export interface GetAchievementsRequest {
    language?: Language;
}

export interface GetPlayerMissionsRequest {
    status?: GetPlayerMissionsStatusEnum;
    language?: Language;
}

/**
 * 
 */
export class StatsApi extends runtime.BaseAPI {

    /**
     * Returns the list of available achievements.
     */
    async getAchievementsRaw(requestParameters: GetAchievementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatsAchievements>> {
        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/stats/achievements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatsAchievementsFromJSON(jsonValue));
    }

    /**
     * Returns the list of available achievements.
     */
    async getAchievements(requestParameters: GetAchievementsRequest = {}, initOverrides?: RequestInit): Promise<StatsAchievements> {
        const response = await this.getAchievementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the leaderboard.
     */
    async getPlayerLeaderboardRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerLeaderboardList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/stats/leaderboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerLeaderboardListFromJSON(jsonValue));
    }

    /**
     * Returns the leaderboard.
     */
    async getPlayerLeaderboard(initOverrides?: RequestInit): Promise<PlayerLeaderboardList> {
        const response = await this.getPlayerLeaderboardRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the player missions history.
     */
    async getPlayerMissionsRaw(requestParameters: GetPlayerMissionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerMissionsHistory>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/stats/missions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerMissionsHistoryFromJSON(jsonValue));
    }

    /**
     * Returns the player missions history.
     */
    async getPlayerMissions(requestParameters: GetPlayerMissionsRequest = {}, initOverrides?: RequestInit): Promise<PlayerMissionsHistory> {
        const response = await this.getPlayerMissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the player statistics .
     */
    async getPlayerStatsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerStats>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/stats/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerStatsFromJSON(jsonValue));
    }

    /**
     * Returns the player statistics .
     */
    async getPlayerStats(initOverrides?: RequestInit): Promise<PlayerStats> {
        const response = await this.getPlayerStatsRaw(initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetPlayerMissionsStatusEnum {
    FAILED = 'failed',
    COMPLETED = 'completed'
}
