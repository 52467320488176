<template>
  <header
    v-if="currentPage.meta.showHeader && isAuthenticated"
    class="bg-darkBlue print:hidden h-[76px] grid grid-cols-3 py-2 px-5 items-center content-center">
    <HeaderStatus />
    <HeaderTitle />
    <HeaderUser />
  </header>
  <header v-else class="hidden print:block"><HeaderTitle /></header>

  <div
    class="flex-1 relative overflow-y-auto print:overflow-y-visible print:break-inside-avoid p-5">
    <transition
      appear
      enter-from-class="-bottom-[50px] opacity-0"
      leave-to-class="-bottom-[50px] opacity-0">
      <v-error
        class="opacity-100 !fixed backdrop-blur-lg transition-all bottom-0 ease-linear bg-black/40 z-50 left-1/2 -translate-x-1/2"
        :error="capturedError" />
    </transition>
    <template v-if="currentPage">
      <Transition mode="out-in" name="fade" appear>
        <Suspense timeout="100">
          <component :is="currentPage.component" :key="currentPage.name" />
          <template #fallback>
            <div class="flex h-full justify-center items-center">
              <v-loading class="h-64 w-64" />
            </div>
          </template>
        </Suspense>
      </Transition>
    </template>
  </div>
</template>

<script setup lang="ts">
  import '@/styles/index.css';
  import '@/styles/transitions.css';
  import '@/styles/activable.css';
  import '@/styles/scrollbar.css';
  import HeaderTitle from '@/components/VHeaderTitle.vue';
  import HeaderStatus from '@/components/VHeaderStatus.vue';
  import HeaderUser from '@/components/VHeaderUser.vue';
  import VLoading from '@/components/VLoading.vue';
  import VError from '@/components/VError.vue';
  import { onErrorCaptured, ref, computed } from 'vue';
  import { useState } from '@/composables/State';
  import { usePlayer } from '@/composables/Player';
  import { useI18n } from 'vue-i18n';
  import { useTitle } from '@vueuse/core';
  import { captureException } from '@sentry/vue';

  const { player, isAuthenticated, changeLanguage, language } = usePlayer();
  const { t } = useI18n();

  const { currentPage, titleOptions, current } = useState();
  const capturedError = ref<Error>();

  const pageTitle = computed(() => {
    return `SOAR | ${t(`state.${currentPage.value.name}`, titleOptions.value)}`;
  });

  useTitle(pageTitle);
  changeLanguage(language.value || 'en');
  onErrorCaptured((error) => {
    console.error(error);
    capturedError.value = error;
    captureException(error, {
      user: {
        id: player.value.id,
        callSign: player.value.callSign,
      },
      extra: {
        currentStage: current.value,
      },
    });
    return false;
  });
</script>

<i18n lang="yaml" global>
en:
  state.Boarding: Boarding
  state.Boarding.subtitle: Choose your next mission
  state.Briefing: '{location}'
  state.Briefing.subtitle: '{mission}'
  state.Debrief: Debrief
  state.Debrief.subtitle: Thanks for participating
  state.EarthTest: Earth Test
  state.EarthTest.subtitle: Just a test
  state.Formation: Intel
  state.Formation.subtitle: Welcome to our program
  state.Report: Report
  state.Report.subtitle: '{callsign}'
  state.LoggedOff: Farewell
  state.Login: Connection
fr:
  state.Boarding: Embarquement
  state.Boarding.subtitle: Choisissez votre prochaine mission
  state.Briefing: '{location}'
  state.Briefing.subtitle: '{mission}'
  state.Debrief: Debrief
  state.Debrief.subtitle: Merci d'avoir participé
  state.EarthTest: Earth Test
  state.EarthTest.subtitle: Juste un test
  state.Formation: Renseignement
  state.Formation.subtitle: Bienvenue dans notre programme
  state.Report: Rapport
  state.Report.subtitle: '{callsign}'
  state.LoggedOff: A bientôt
  state.Login: Connexion
</i18n>
