/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * The mission Id linked to the feedback ('global' means the feedback is for the whole assessment)
     * @type {string}
     * @memberof Feedback
     */
    missionId: string;
    /**
     * The player feedback
     * @type {string}
     * @memberof Feedback
     */
    message: string;
    /**
     * The player score for the mission.
     * @type {number}
     * @memberof Feedback
     */
    score: number;
}

export function FeedbackFromJSON(json: any): Feedback {
    return FeedbackFromJSONTyped(json, false);
}

export function FeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionId': json['missionId'],
        'message': json['message'],
        'score': json['score'],
    };
}

export function FeedbackToJSON(value?: Feedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'missionId': value.missionId,
        'message': value.message,
        'score': value.score,
    };
}

