/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Achievement
 */
export interface Achievement {
    /**
     * The achievement Id
     * @type {string}
     * @memberof Achievement
     */
    id: string;
    /**
     * The achievement name
     * @type {string}
     * @memberof Achievement
     */
    name: string;
    /**
     * The achievement description in markdown format.
     * @type {string}
     * @memberof Achievement
     */
    description: string;
    /**
     * Link of the achievement svg logo.
     * @type {string}
     * @memberof Achievement
     */
    link: string;
}

export function AchievementFromJSON(json: any): Achievement {
    return AchievementFromJSONTyped(json, false);
}

export function AchievementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Achievement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'link': json['link'],
    };
}

export function AchievementToJSON(value?: Achievement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'link': value.link,
    };
}

