/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The player status.
 * @export
 * @enum {string}
 */
export enum PlayerStatus {
    ACTIVATED = 'activated',
    IDLE = 'idle',
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    DISABLED = 'disabled'
}

export function PlayerStatusFromJSON(json: any): PlayerStatus {
    return PlayerStatusFromJSONTyped(json, false);
}

export function PlayerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerStatus {
    return json as PlayerStatus;
}

export function PlayerStatusToJSON(value?: PlayerStatus | null): any {
    return value as any;
}

