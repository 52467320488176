/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Achievement,
    AchievementFromJSON,
    AchievementFromJSONTyped,
    AchievementToJSON,
} from './Achievement';

/**
 * 
 * @export
 * @interface StatsAchievements
 */
export interface StatsAchievements {
    /**
     * An array with all available achievements.
     * @type {Array<Achievement>}
     * @memberof StatsAchievements
     */
    achievements: Array<Achievement>;
}

export function StatsAchievementsFromJSON(json: any): StatsAchievements {
    return StatsAchievementsFromJSONTyped(json, false);
}

export function StatsAchievementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatsAchievements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'achievements': ((json['achievements'] as Array<any>).map(AchievementFromJSON)),
    };
}

export function StatsAchievementsToJSON(value?: StatsAchievements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'achievements': ((value.achievements as Array<any>).map(AchievementToJSON)),
    };
}

