<template>
  <div
    class="justify-self-end relative text-sm cursor-pointer"
    @click.stop="isOpen = !isOpen">
    <div class="grid grid-cols-[1fr,1px,auto] gap-3">
      <button ref="droptDownRef" class="items-center gap-1">
        <p class="flex flex-col text-left">
          <small class="text-gray-500 leading-tight">agent</small>
          <span class="text-lg leading-tight">{{ player.callSign }}</span>
        </p>
      </button>

      <div class="bg-white w-[1px] h-4/5 justify-center"></div>
      <img class="w-12" src="@/assets/icons/power.svg" />
    </div>
    <nav
      tabindex="0"
      class="border border-1 border-white/50 bg-darkBlue w-60 absolute right-3.5 transition-all z-50"
      :class="{
        'visible opacity-100 translate-y-1': isOpen,
        ' invisible opacity-0 translate-y-0': !isOpen,
      }">
      <ul>
        <li
          class="grid grid-cols-[16px,1fr] items-center gap-x-1.5 px-2 py-2 hover:bg-white/10 cursor-pointer"
          @click.stop="changeLanguage(language === 'fr' ? 'en' : 'fr')">
          <img src="@/assets/icons/language.svg" />
          <span>{{ t('header.user.language') }}</span>
        </li>
        <li
          class="grid grid-cols-[16px,1fr] items-center gap-x-1.5 px-2 py-2 hover:bg-white/10 cursor-pointer"
          @click.stop="goToFormation()">
          <img src="@/assets/icons/help.svg" />
          <span>{{ t('header.user.intel') }}</span>
        </li>
        <li
          class="grid grid-cols-[16px,1fr] items-center gap-x-1.5 px-2 py-2 hover:bg-white/10 cursor-pointer"
          @click.stop="logout()">
          <img src="@/assets/icons/logout.svg" />

          <span>{{ t('header.user.logout') }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import { usePlayer } from '@/composables/Player';
  import { ref } from 'vue';
  import { onClickOutside } from '@vueuse/core';
  import { useState } from '@/composables/State';
  const { t } = useI18n();
  const { player, changeLanguage, logout, language } = usePlayer();
  const { goToFormation } = useState();
  let isOpen = ref(false);
  const droptDownRef = ref(null);
  onClickOutside(droptDownRef, () => (isOpen.value = false));
</script>

<i18n lang="yaml">
en:
  header.user.logout: 'Log out'
  header.user.language: 'French'
  header.user.intel: 'Intel'

fr:
  header.user.logout: 'Déconnection'
  header.user.language: 'English'
  header.user.intel: 'Renseignement'
</i18n>
