/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerCreateResult
 */
export interface PlayerCreateResult {
    /**
     * The new player's ID
     * @type {string}
     * @memberof PlayerCreateResult
     */
    id: string;
    /**
     * The new player's access code
     * @type {string}
     * @memberof PlayerCreateResult
     */
    accessCode: string;
    /**
     * The new player's call sign
     * @type {string}
     * @memberof PlayerCreateResult
     */
    callSign: string;
    /**
     * A message containing the new player's access code
     * @type {string}
     * @memberof PlayerCreateResult
     */
    message: string;
    /**
     * An optional message describing the player resources (missions) not provisioned
     * @type {string}
     * @memberof PlayerCreateResult
     */
    warning: string;
}

export function PlayerCreateResultFromJSON(json: any): PlayerCreateResult {
    return PlayerCreateResultFromJSONTyped(json, false);
}

export function PlayerCreateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerCreateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accessCode': json['accessCode'],
        'callSign': json['callSign'],
        'message': json['message'],
        'warning': json['warning'],
    };
}

export function PlayerCreateResultToJSON(value?: PlayerCreateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accessCode': value.accessCode,
        'callSign': value.callSign,
        'message': value.message,
        'warning': value.warning,
    };
}

