import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import sanitize from '@/plugins/sanitize';
import { init as initSentry } from '@sentry/vue';

const app = createApp(App);

const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production';
if (isProduction) {
    try {
        initSentry({
            app,
            dsn: import.meta.env.VITE_SENTRY_KEY,
            attachProps: true,
            release: PACKAGE_VERSION,
            tracesSampleRate: 0.2,
            environment: import.meta.env.VITE_ENVIRONMENT,
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Could not initialize Sentry', e);
    }
}

app.use(i18n).use(sanitize).mount('#app');
