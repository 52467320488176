/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PlayerCreate,
    PlayerCreateFromJSON,
    PlayerCreateToJSON,
    PlayerCreateResult,
    PlayerCreateResultFromJSON,
    PlayerCreateResultToJSON,
    PlayerInformation,
    PlayerInformationFromJSON,
    PlayerInformationToJSON,
    PlayerUpdate,
    PlayerUpdateFromJSON,
    PlayerUpdateToJSON,
    PlayerUpdateResult,
    PlayerUpdateResultFromJSON,
    PlayerUpdateResultToJSON,
} from '../models';

export interface CreatePlayerRequest {
    playerCreate: PlayerCreate;
}

export interface PlayerInformationRequest {
    playerId: string;
}

export interface UpdatePlayerRequest {
    playerUpdate: PlayerUpdate;
}

/**
 * 
 */
export class PlayersApi extends runtime.BaseAPI {

    /**
     * Request to create a new player
     */
    async createPlayerRaw(requestParameters: CreatePlayerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerCreateResult>> {
        if (requestParameters.playerCreate === null || requestParameters.playerCreate === undefined) {
            throw new runtime.RequiredError('playerCreate','Required parameter requestParameters.playerCreate was null or undefined when calling createPlayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/players`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerCreateToJSON(requestParameters.playerCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerCreateResultFromJSON(jsonValue));
    }

    /**
     * Request to create a new player
     */
    async createPlayer(requestParameters: CreatePlayerRequest, initOverrides?: RequestInit): Promise<PlayerCreateResult> {
        const response = await this.createPlayerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the player information.
     */
    async playerInformationRaw(requestParameters: PlayerInformationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerInformation>> {
        if (requestParameters.playerId === null || requestParameters.playerId === undefined) {
            throw new runtime.RequiredError('playerId','Required parameter requestParameters.playerId was null or undefined when calling playerInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/players/{playerId}`.replace(`{${"playerId"}}`, encodeURIComponent(String(requestParameters.playerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerInformationFromJSON(jsonValue));
    }

    /**
     * Returns the player information.
     */
    async playerInformation(requestParameters: PlayerInformationRequest, initOverrides?: RequestInit): Promise<PlayerInformation> {
        const response = await this.playerInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request to update a player
     */
    async updatePlayerRaw(requestParameters: UpdatePlayerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlayerUpdateResult>> {
        if (requestParameters.playerUpdate === null || requestParameters.playerUpdate === undefined) {
            throw new runtime.RequiredError('playerUpdate','Required parameter requestParameters.playerUpdate was null or undefined when calling updatePlayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // zelab.api.lambda.authorizer authentication
        }

        const response = await this.request({
            path: `/v1/players`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerUpdateToJSON(requestParameters.playerUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerUpdateResultFromJSON(jsonValue));
    }

    /**
     * Request to update a player
     */
    async updatePlayer(requestParameters: UpdatePlayerRequest, initOverrides?: RequestInit): Promise<PlayerUpdateResult> {
        const response = await this.updatePlayerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
