<template>
  <pre
    v-if="formattedError && !dismissed"
    class="bg-black text-red-700 font-mono whitespace-pre-wrap m-2 p-4 rounded-lg border border-red-600/50 relative">{{ formattedError }}
    <img alt="dismiss" class="rounded-full p-1 w-6 bg-red-900 absolute top-2 right-2 cursor-pointer" src="@/assets/icons/close.svg" @click.stop="dismissed=true"/>
    </pre>
</template>

<script lang="ts" setup>
  import { ApplicationError, toErrorMessage } from '@/models/Errors.model';
  import { watch, ref } from 'vue';
  interface Props {
    error?: ApplicationError;
  }

  const dismissed = ref(false);
  const props = withDefaults(defineProps<Props>(), {
    error: undefined,
  });

  const formattedError = ref('');
  watch(
    () => props.error,
    async (error) => {
      formattedError.value = await toErrorMessage(error);
      dismissed.value = false;
    }
  );
</script>
