/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The player language.
 * @export
 * @enum {string}
 */
export enum Language {
    EN = 'en',
    FR = 'fr'
}

export function LanguageFromJSON(json: any): Language {
    return LanguageFromJSONTyped(json, false);
}

export function LanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Language {
    return json as Language;
}

export function LanguageToJSON(value?: Language | null): any {
    return value as any;
}

