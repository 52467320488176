/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import {
    PlayerCreateRequester,
    PlayerCreateRequesterFromJSON,
    PlayerCreateRequesterFromJSONTyped,
    PlayerCreateRequesterToJSON,
} from './PlayerCreateRequester';

/**
 * 
 * @export
 * @interface PlayerCreate
 */
export interface PlayerCreate {
    /**
     * The player first name
     * @type {string}
     * @memberof PlayerCreate
     */
    firstName: string;
    /**
     * The player last name
     * @type {string}
     * @memberof PlayerCreate
     */
    lastName: string;
    /**
     * The player email
     * @type {string}
     * @memberof PlayerCreate
     */
    email: string;
    /**
     * 
     * @type {Language}
     * @memberof PlayerCreate
     */
    language: Language;
    /**
     * List of available missions Id for the player
     * @type {Array<string>}
     * @memberof PlayerCreate
     */
    missionsAvailable?: Array<string>;
    /**
     * List of missions Id which must be completed by the player
     * @type {Array<string>}
     * @memberof PlayerCreate
     */
    missionsMandatory?: Array<string>;
    /**
     * How many days the player has to complete the missions
     * @type {number}
     * @memberof PlayerCreate
     */
    activatedInDays: number;
    /**
     * Defines the player's scope.
     * @type {string}
     * @memberof PlayerCreate
     */
    scope: PlayerCreateScopeEnum;
    /**
     * 
     * @type {PlayerCreateRequester}
     * @memberof PlayerCreate
     */
    requester?: PlayerCreateRequester;
}

/**
* @export
* @enum {string}
*/
export enum PlayerCreateScopeEnum {
    ADMIN = 'admin',
    USER = 'user'
}

export function PlayerCreateFromJSON(json: any): PlayerCreate {
    return PlayerCreateFromJSONTyped(json, false);
}

export function PlayerCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'language': LanguageFromJSON(json['language']),
        'missionsAvailable': !exists(json, 'missionsAvailable') ? undefined : json['missionsAvailable'],
        'missionsMandatory': !exists(json, 'missionsMandatory') ? undefined : json['missionsMandatory'],
        'activatedInDays': json['activatedInDays'],
        'scope': json['scope'],
        'requester': !exists(json, 'requester') ? undefined : PlayerCreateRequesterFromJSON(json['requester']),
    };
}

export function PlayerCreateToJSON(value?: PlayerCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'language': LanguageToJSON(value.language),
        'missionsAvailable': value.missionsAvailable,
        'missionsMandatory': value.missionsMandatory,
        'activatedInDays': value.activatedInDays,
        'scope': value.scope,
        'requester': PlayerCreateRequesterToJSON(value.requester),
    };
}

