const EARTH_RADIUS_METERS = 6371e3;
const ONE_KM = 1000;

export type LATITUDE = number;
export type LONGITUDE = number;
export type GPS_LOCATION = [LATITUDE, LONGITUDE];

export const useCalculations = () => {

    const calculateDistance = (
        [lat1, lon1]: GPS_LOCATION,
        [lat2, lon2]: GPS_LOCATION
    ) => {
        const φ1 = (lat1 * Math.PI) / 180;
        const φ2 = (lat2 * Math.PI) / 180;
        const Δφ = ((lat2 - lat1) * Math.PI) / 180;
        const Δλ = ((lon2 - lon1) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return Math.round((EARTH_RADIUS_METERS * c) / ONE_KM);
    };

    const calculateJourneyDistance = (locations: GPS_LOCATION[]) => {
        return locations.reduce(
            (acc, curr) => {
                if (acc.lastLocation) {
                    acc.total = acc.total + calculateDistance(acc.lastLocation, curr);
                }
                acc.lastLocation = curr;
                return acc;
            },
            { lastLocation: undefined as GPS_LOCATION | undefined, total: 0 }
        ).total;
    };

    const toPercent = (val: number, total: number): string => {
        const percent = Math.round((val * 100) / total);
        if (percent > 100) {
            console.warn(`Percentage higher than 100%: ${percent}%`);
            return '100%'
        }
        return `${percent < 0 ? 0 : percent}%`;
    }

    const friendlyTimeFormat = (timeSpanMs: number) => {
        const withLeadingZero = (number: number) => String(number).padStart(2, '0');
        const hours = Math.floor(timeSpanMs / 3600);
        const minutes = Math.floor((timeSpanMs % 3600) / 60);
        const seconds = Math.floor((timeSpanMs % 3600) % 60);

        return `${withLeadingZero(hours)}:${withLeadingZero(
            minutes
        )}:${withLeadingZero(seconds)}`;
    };

    return {
        friendlyTimeFormat,
        calculateDistance,
        calculateJourneyDistance,
        toPercent
    };
};
