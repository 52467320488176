export interface ApiErrorContent {
  id: string;
  code: string;
  description: string;
}

export type ApplicationError = Error | string | Response;

/* Classing duck typing utilities for type guards */

export function isResponseError(error: ApplicationError): error is Response {
  return (error as Response).json !== undefined;
}

export function isError(error: ApplicationError): error is Error {
  return error instanceof Error;
}

export function isPlainString(error: ApplicationError): error is string {
  return error instanceof String;
}

export async function toErrorMessage(error: ApplicationError): Promise<string> {
  if (isResponseError(error)) {
    try {
      const jsonError: ApiErrorContent = await error.json();
      return `${jsonError.code}\n${jsonError.description}\nError id:${jsonError.id}`;
    } catch (e) {
      console.warn('Non json error caught', e);
      return error.text();
    }
  }

  if (isError(error)) {
    return error.toString();
  }

  return error;
}
