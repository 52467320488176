import {
  CommonApi,
  Configuration,
  Language,
} from '@/generated-api';
import { ApplicationError } from '@/models/Errors.model';
import { readonly, reactive, toRefs } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { useState } from '@/composables/State';
import { Locale, useI18n } from 'vue-i18n';

export type PlayerInformation = {
  fullName: string;
  locale: string;
  id: string;
  firstName: string;
  lastName: string;
  latestConnection?: Date;
  firstConnection: boolean;
  status: string;
  hash: string;
  callSign: string;
};

const state = reactive({
  jwt: useLocalStorage('player.jwt', ''),
  language: useLocalStorage<Language>('player.language', 'en' as Language),
  isAuthenticated: useLocalStorage('player.isAuthenticated', false),
  loginError: '' as ApplicationError,
  loginLoading: false,
  player: useLocalStorage('player.info', {} as PlayerInformation),
});

export const VALID_LANGUAGES_LOCALE: Map<string, string> = new Map([
  ['fr', 'fr-FR'],
  ['en', 'en-GB'],
]);

export function usePlayer() {
  const configuration = new Configuration({
    basePath: import.meta.env.VITE_BASE_URL_API,
  });
  const commonApi = new CommonApi(configuration);
  const mainState = useState();
  const { locale, availableLocales } = useI18n({ useScope: 'global' });

  async function login(accessCode: string) {
    state.loginError = '';
    state.loginLoading = true;

    try {
      const player = await commonApi.connectPlayer({
        inlineObject: { accessCode },
      });
      state.jwt = player.jwt;
      state.player = {
        id: player.playerId,
        firstName: player.firstName,
        lastName: player.lastName,
        latestConnection: player.latestConnection,
        firstConnection: player.firstConnection === player.latestConnection,
        status: player.status,
        hash: player.hash,
        callSign: player.callSign,
        fullName: `${player.firstName} ${player.lastName}`,
        locale: VALID_LANGUAGES_LOCALE.get(player.language || 'en') || '',
      };

      changeLanguage(state.player.firstConnection ? player.language : state.language);

      state.isAuthenticated = true;
      mainState.setFirstConnection(state.player.firstConnection);
      mainState.goToBoarding();
    } catch (e) {
      state.isAuthenticated = false;
      state.jwt = '';
      state.player = {} as PlayerInformation;
      state.loginError = e as ApplicationError;
    } finally {
      state.loginLoading = false;
    }
  }

  async function logout() {
    state.isAuthenticated = false;
    state.jwt = '';
    state.player = {} as PlayerInformation;
    mainState.goToLogOff();
  }

  function changeLanguage(language: Locale): void {
    if (!isValidLanguage(language)) {
      throw new Error(`Invalid language ${language}`);
    }
    state.language = language as Language;
    state.player.locale = VALID_LANGUAGES_LOCALE.get(language || 'en') || '';
    locale.value = language;
  }

  function isValidLanguage(lang: string) {
    if (!lang || !availableLocales.includes(lang)) {
      console.warn(`Invalid language detected: ${lang}. Skipping switch`);
      return false;
    }
    return true;
  }

  return {
    ...toRefs(readonly(state)),
    login,
    logout,
    changeLanguage,
    isValidLanguage,
  };
}
