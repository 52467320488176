/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerLeaderboard,
    PlayerLeaderboardFromJSON,
    PlayerLeaderboardFromJSONTyped,
    PlayerLeaderboardToJSON,
} from './PlayerLeaderboard';

/**
 * A list containing ranked players.
 * @export
 * @interface PlayerLeaderboardList
 */
export interface PlayerLeaderboardList {
    /**
     * 
     * @type {Array<PlayerLeaderboard>}
     * @memberof PlayerLeaderboardList
     */
    leaderboard: Array<PlayerLeaderboard>;
}

export function PlayerLeaderboardListFromJSON(json: any): PlayerLeaderboardList {
    return PlayerLeaderboardListFromJSONTyped(json, false);
}

export function PlayerLeaderboardListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerLeaderboardList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboard': ((json['leaderboard'] as Array<any>).map(PlayerLeaderboardFromJSON)),
    };
}

export function PlayerLeaderboardListToJSON(value?: PlayerLeaderboardList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboard': ((value.leaderboard as Array<any>).map(PlayerLeaderboardToJSON)),
    };
}

