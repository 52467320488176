/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Send feedback operation result
 * @export
 * @interface SendFeedback
 */
export interface SendFeedback {
    /**
     * true if the feedback has been successfully saved
     * @type {boolean}
     * @memberof SendFeedback
     */
    saved: boolean;
}

export function SendFeedbackFromJSON(json: any): SendFeedback {
    return SendFeedbackFromJSONTyped(json, false);
}

export function SendFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saved': json['saved'],
    };
}

export function SendFeedbackToJSON(value?: SendFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saved': value.saved,
    };
}

