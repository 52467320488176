/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RewardsBy,
    RewardsByFromJSON,
    RewardsByFromJSONTyped,
    RewardsByToJSON,
} from './RewardsBy';

/**
 * 
 * @export
 * @interface PlayerStatsSummary
 */
export interface PlayerStatsSummary {
    /**
     * The player id
     * @type {string}
     * @memberof PlayerStatsSummary
     */
    id: string;
    /**
     * The player callSign
     * @type {string}
     * @memberof PlayerStatsSummary
     */
    callSign: string;
    /**
     * The total amount of rewards earned by the player
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    rewardsEarned: number;
    /**
     * The maximum rewards that the player might have won
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    maxRewards: number;
    /**
     * Total of mission stages available for the player
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    totalStages?: number;
    /**
     * Total of mission stages completed by the player
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    stagesCompleted?: number;
    /**
     * Total of mission stages failed by the player
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    stagesFailed?: number;
    /**
     * Total of missions completed by the player
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    missionsCompleted: number;
    /**
     * Total of missions failed by the player
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    missionsFailed: number;
    /**
     * An integer representing the total player playtime, in minutes.
     * @type {number}
     * @memberof PlayerStatsSummary
     */
    lifetimePlayTimeInMinutes: number;
    /**
     * An Array with all badges earned by the player
     * @type {Array<string>}
     * @memberof PlayerStatsSummary
     */
    badges: Array<string>;
    /**
     * An array of rewards by category
     * @type {Array<RewardsBy>}
     * @memberof PlayerStatsSummary
     */
    rewardsByCategory: Array<RewardsBy>;
    /**
     * An array of rewards by sub category
     * @type {Array<RewardsBy>}
     * @memberof PlayerStatsSummary
     */
    rewardsBySubCategory: Array<RewardsBy>;
    /**
     * An array of rewards by service
     * @type {Array<RewardsBy>}
     * @memberof PlayerStatsSummary
     */
    rewardsByService: Array<RewardsBy>;
    /**
     * An array of rewards by application
     * @type {Array<RewardsBy>}
     * @memberof PlayerStatsSummary
     */
    rewardsByApplication: Array<RewardsBy>;
}

export function PlayerStatsSummaryFromJSON(json: any): PlayerStatsSummary {
    return PlayerStatsSummaryFromJSONTyped(json, false);
}

export function PlayerStatsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerStatsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'callSign': json['callSign'],
        'rewardsEarned': json['rewardsEarned'],
        'maxRewards': json['maxRewards'],
        'totalStages': !exists(json, 'totalStages') ? undefined : json['totalStages'],
        'stagesCompleted': !exists(json, 'stagesCompleted') ? undefined : json['stagesCompleted'],
        'stagesFailed': !exists(json, 'stagesFailed') ? undefined : json['stagesFailed'],
        'missionsCompleted': json['missionsCompleted'],
        'missionsFailed': json['missionsFailed'],
        'lifetimePlayTimeInMinutes': json['lifetimePlayTimeInMinutes'],
        'badges': json['badges'],
        'rewardsByCategory': ((json['rewardsByCategory'] as Array<any>).map(RewardsByFromJSON)),
        'rewardsBySubCategory': ((json['rewardsBySubCategory'] as Array<any>).map(RewardsByFromJSON)),
        'rewardsByService': ((json['rewardsByService'] as Array<any>).map(RewardsByFromJSON)),
        'rewardsByApplication': ((json['rewardsByApplication'] as Array<any>).map(RewardsByFromJSON)),
    };
}

export function PlayerStatsSummaryToJSON(value?: PlayerStatsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'callSign': value.callSign,
        'rewardsEarned': value.rewardsEarned,
        'maxRewards': value.maxRewards,
        'totalStages': value.totalStages,
        'stagesCompleted': value.stagesCompleted,
        'stagesFailed': value.stagesFailed,
        'missionsCompleted': value.missionsCompleted,
        'missionsFailed': value.missionsFailed,
        'lifetimePlayTimeInMinutes': value.lifetimePlayTimeInMinutes,
        'badges': value.badges,
        'rewardsByCategory': ((value.rewardsByCategory as Array<any>).map(RewardsByToJSON)),
        'rewardsBySubCategory': ((value.rewardsBySubCategory as Array<any>).map(RewardsByToJSON)),
        'rewardsByService': ((value.rewardsByService as Array<any>).map(RewardsByToJSON)),
        'rewardsByApplication': ((value.rewardsByApplication as Array<any>).map(RewardsByToJSON)),
    };
}

