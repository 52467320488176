/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerStatsSummary,
    PlayerStatsSummaryFromJSON,
    PlayerStatsSummaryFromJSONTyped,
    PlayerStatsSummaryToJSON,
} from './PlayerStatsSummary';

/**
 * 
 * @export
 * @interface PlayerStats
 */
export interface PlayerStats {
    /**
     * 
     * @type {PlayerStatsSummary}
     * @memberof PlayerStats
     */
    summary: PlayerStatsSummary;
}

export function PlayerStatsFromJSON(json: any): PlayerStats {
    return PlayerStatsFromJSONTyped(json, false);
}

export function PlayerStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': PlayerStatsSummaryFromJSON(json['summary']),
    };
}

export function PlayerStatsToJSON(value?: PlayerStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': PlayerStatsSummaryToJSON(value.summary),
    };
}

