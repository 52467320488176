/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Feedback,
    FeedbackFromJSON,
    FeedbackFromJSONTyped,
    FeedbackToJSON,
} from './Feedback';

/**
 * Get all player feedbacks
 * @export
 * @interface FeedbacksList
 */
export interface FeedbacksList {
    /**
     * 
     * @type {Array<Feedback>}
     * @memberof FeedbacksList
     */
    feedbacks: Array<Feedback>;
}

export function FeedbacksListFromJSON(json: any): FeedbacksList {
    return FeedbacksListFromJSONTyped(json, false);
}

export function FeedbacksListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbacksList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbacks': ((json['feedbacks'] as Array<any>).map(FeedbackFromJSON)),
    };
}

export function FeedbacksListToJSON(value?: FeedbacksList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbacks': ((value.feedbacks as Array<any>).map(FeedbackToJSON)),
    };
}

