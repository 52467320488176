<template>
  <div class="grid grid-cols-[82px_1fr] items-center gap-3 w-[400px]">
    <img src="@/assets/logo.svg" :alt="t('logo.alt')" />
    <div class="grid grid-rows-2 gap-y-1">
      <article class="group relative flex items-center content-center">
        <img
          :class="{ 'opacity-50': remainingTimePercentage === '0%' }"
          class="w-[28px] h-[28px]"
          src="@/assets/icons/lightning-bolt-outline.svg"
          alt="test" />
        <div
          class="h-[18px] w-full relative rounded flex"
          :style="{
            'background-image':
              'repeating-linear-gradient(90deg,var(--time-remaining-darker),var(--time-remaining-darker)  10px,transparent 10px,transparent 14px)',
          }">
          <transition appear>
            <div
              :style="{
                width: remainingTimePercentage,
                'background-image':
                  'repeating-linear-gradient(90deg,var(--time-remaining),var(--time-remaining) 10px,transparent 10px,transparent 14px)',
              }"
              class="relative w-1 rounded transition-all ease-in-out duration-1000"></div>
          </transition>
        </div>
        <section
          v-if="remainingTimePercentage === '0%'"
          class="absolute bg-black/70 w-full h-full flex items-center justify-center text-white">
          {{ t('time.expired') }}
        </section>
        <p
          class="hidden text-sm backdrop-blur-[2px] bg-black/40 group-hover:flex items-center justify-center w-full h-full leading-none absolute text-white">
          {{ t('time.left', { timeLeft }) }}
        </p>
      </article>
      <article class="group relative flex items-center content-center">
        <div class="z-10 rounded-full bg-white">
          <img
            class="invert w-[28px] h-[28px]"
            src="@/assets/icons/target.svg"
            alt="test" />
        </div>

        <div class="w-full relative h-[18px] -ml-3 bg-white/30 rounded">
          <transition appear>
            <div
              :style="{ width: progressPercentage }"
              class="bg-white h-full w-1 rounded transition-all ease-in-out duration-1000"></div>
          </transition>
        </div>
        <p
          v-if="progressPercentage === '100%'"
          class="absolute flex items-center justify-center text-sm z-20 w-full h-full text-black">
          {{ t('progress.complete') }}
        </p>
        <p
          class="hidden text-sm backdrop-blur-[2px] absolute items-center justify-center leading-tight z-20 bg-black/60 group-hover:flex w-full h-full text-white">
          {{ t('progress.current', { progressPercentage }) }}
        </p>
      </article>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useNow } from '@vueuse/core';
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { usePlayerInfo } from '@/composables/PlayerInfo';
  import { usePlayerStats } from '@/composables/PlayerStats';
  import { useCalculations } from '@/composables/Calculations';

  const { t } = useI18n();
  const now = useNow({ interval: 5000 });

  const { toPercent } = useCalculations();
  const { getPlayerInfo } = usePlayerInfo();
  const { data: playerInfo } = getPlayerInfo();
  const { getPlayerStats } = usePlayerStats();
  const { data: stat } = getPlayerStats();
  const progressPercentage = computed(() => {
    if (!stat.value) {
      return '0%';
    }
    const failedStages = stat.value.stagesFailed ?? 0;
    const completedStages = stat.value.stagesCompleted ?? 0;

    return toPercent(
      failedStages + completedStages,
      stat.value.totalStages ?? 1
    );
  });

  const remainingTimePercentage = computed(() => {
    if (!playerInfo.value) {
      return '0%';
    }
    const activatedTime = playerInfo.value.activatedUntil.getTime();
    const firstConnectionTime = (
      playerInfo.value.firstConnection ?? new Date()
    ).getTime();
    const initialTimeLeft = activatedTime - firstConnectionTime;
    const currentTimeLeft = activatedTime - now.value.getTime();

    return toPercent(currentTimeLeft, initialTimeLeft);
  });

  const timeLeft = computed(() => {
    if (!playerInfo.value) {
      return '';
    }
    return playerInfo.value.activatedUntil.toLocaleString();
  });
</script>
<style lang="css">
  :root {
    --time-remaining: rgb(255, 165, 0);
    --time-remaining-darker: rgba(255, 165, 0, 0.2);
  }
</style>
<i18n lang="yaml">
en:
  logo.alt: Application logo
  time.left: 'Finish by {timeLeft}'
  time.expired: 'Time expired'
  progress.complete: Complete
  progress.current: 'You completed {progressPercentage}'
fr:
  logo.alt: "Logo de l'application"
  time.left: 'Finissez avant le {timeLeft}'
  time.expired: 'Temps écoulé'
  progress.complete: Terminé!
  progress.current: 'Vous avez complété {progressPercentage}'
</i18n>
