/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import {
    PlayerStatus,
    PlayerStatusFromJSON,
    PlayerStatusFromJSONTyped,
    PlayerStatusToJSON,
} from './PlayerStatus';

/**
 * 
 * @export
 * @interface PlayerInformationPlayer
 */
export interface PlayerInformationPlayer {
    /**
     * The Player Id
     * @type {string}
     * @memberof PlayerInformationPlayer
     */
    id: string;
    /**
     * The player callSign
     * @type {string}
     * @memberof PlayerInformationPlayer
     */
    callSign: string;
    /**
     * The report's hash key
     * @type {string}
     * @memberof PlayerInformationPlayer
     */
    hash: string;
    /**
     * The player first name
     * @type {string}
     * @memberof PlayerInformationPlayer
     */
    firstName: string;
    /**
     * The player last name
     * @type {string}
     * @memberof PlayerInformationPlayer
     */
    lastName: string;
    /**
     * The latest player connection date (date format as defined by RFC 3339, section 5.6 for example, 2017-07-21T17:32:28Z)
     * @type {Date}
     * @memberof PlayerInformationPlayer
     */
    latestConnection: Date;
    /**
     * The first player connection date (date format as defined by RFC 3339, section 5.6 for example, 2017-07-21T17:32:28Z)
     * @type {Date}
     * @memberof PlayerInformationPlayer
     */
    firstConnection?: Date;
    /**
     * The date until which the player can play (date format as defined by RFC 3339, section 5.6 for example, 2017-07-21T17:32:28Z)
     * @type {Date}
     * @memberof PlayerInformationPlayer
     */
    activatedUntil: Date;
    /**
     * An integer representing the total player playtime, in minutes.
     * @type {number}
     * @memberof PlayerInformationPlayer
     */
    lifetimePlayTimeInMinutes: number;
    /**
     * List of available missions Id for the player
     * @type {Array<string>}
     * @memberof PlayerInformationPlayer
     */
    missionsAvailable: Array<string>;
    /**
     * List of missions Id which must be completed by the player
     * @type {Array<string>}
     * @memberof PlayerInformationPlayer
     */
    missionsMandatory: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof PlayerInformationPlayer
     */
    language: Language;
    /**
     * 
     * @type {PlayerStatus}
     * @memberof PlayerInformationPlayer
     */
    status: PlayerStatus;
}

export function PlayerInformationPlayerFromJSON(json: any): PlayerInformationPlayer {
    return PlayerInformationPlayerFromJSONTyped(json, false);
}

export function PlayerInformationPlayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerInformationPlayer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'callSign': json['callSign'],
        'hash': json['hash'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'latestConnection': (new Date(json['latestConnection'])),
        'firstConnection': !exists(json, 'firstConnection') ? undefined : (new Date(json['firstConnection'])),
        'activatedUntil': (new Date(json['activatedUntil'])),
        'lifetimePlayTimeInMinutes': json['lifetimePlayTimeInMinutes'],
        'missionsAvailable': json['missionsAvailable'],
        'missionsMandatory': json['missionsMandatory'],
        'language': LanguageFromJSON(json['language']),
        'status': PlayerStatusFromJSON(json['status']),
    };
}

export function PlayerInformationPlayerToJSON(value?: PlayerInformationPlayer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'callSign': value.callSign,
        'hash': value.hash,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'latestConnection': (value.latestConnection.toISOString()),
        'firstConnection': value.firstConnection === undefined ? undefined : (value.firstConnection.toISOString()),
        'activatedUntil': (value.activatedUntil.toISOString()),
        'lifetimePlayTimeInMinutes': value.lifetimePlayTimeInMinutes,
        'missionsAvailable': value.missionsAvailable,
        'missionsMandatory': value.missionsMandatory,
        'language': LanguageToJSON(value.language),
        'status': PlayerStatusToJSON(value.status),
    };
}

