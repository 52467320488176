/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerUpdate
 */
export interface PlayerUpdate {
    /**
     * The player id to update
     * @type {string}
     * @memberof PlayerUpdate
     */
    playerId: string;
    /**
     * The requested change
     * @type {string}
     * @memberof PlayerUpdate
     */
    change: PlayerUpdateChangeEnum;
    /**
     * The new data value
     * @type {string}
     * @memberof PlayerUpdate
     */
    data: string;
}

/**
* @export
* @enum {string}
*/
export enum PlayerUpdateChangeEnum {
    ADD_MISSION = 'addMission',
    CHANGE_EXPIRATION_DATE = 'changeExpirationDate'
}

export function PlayerUpdateFromJSON(json: any): PlayerUpdate {
    return PlayerUpdateFromJSONTyped(json, false);
}

export function PlayerUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playerId': json['playerId'],
        'change': json['change'],
        'data': json['data'],
    };
}

export function PlayerUpdateToJSON(value?: PlayerUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playerId': value.playerId,
        'change': value.change,
        'data': value.data,
    };
}

