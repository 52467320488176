import useSWRV from 'swrv';
import { ApiSWRVKey } from './ApiSWRVKey';
import { useApiConfig } from './ApiConfig';
import { PlayerStatsSummary, StatsApi } from '@/generated-api';


export function usePlayerStats() {
  const { configuration } = useApiConfig();
  const statsApi = new StatsApi(configuration.value);


  const getPlayerStats = async (): Promise<PlayerStatsSummary> => {
    const { summary } = await statsApi.getPlayerStats();
    return summary;
  };


  return {
    getPlayerStats: () => useSWRV(ApiSWRVKey.STATS_SUMMARY, getPlayerStats),
  };
}
