/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerMissionHistoryPlayersStats,
    PlayerMissionHistoryPlayersStatsFromJSON,
    PlayerMissionHistoryPlayersStatsFromJSONTyped,
    PlayerMissionHistoryPlayersStatsToJSON,
} from './PlayerMissionHistoryPlayersStats';
import {
    PlayerMissionHistoryStage,
    PlayerMissionHistoryStageFromJSON,
    PlayerMissionHistoryStageFromJSONTyped,
    PlayerMissionHistoryStageToJSON,
} from './PlayerMissionHistoryStage';

/**
 * 
 * @export
 * @interface PlayerMissionHistory
 */
export interface PlayerMissionHistory {
    /**
     * The mission Id
     * @type {string}
     * @memberof PlayerMissionHistory
     */
    missionId: string;
    /**
     * The mission name
     * @type {string}
     * @memberof PlayerMissionHistory
     */
    missionName: string;
    /**
     * The mission location (a city)
     * @type {string}
     * @memberof PlayerMissionHistory
     */
    location: string;
    /**
     * The GPS coordinates ('latitude,longitude').
     * @type {string}
     * @memberof PlayerMissionHistory
     */
    gps: string;
    /**
     * Total playtime on this mission
     * @type {number}
     * @memberof PlayerMissionHistory
     */
    timeElapsedInMinutes: number;
    /**
     * The maximum time, in minutes, allowed to complete the mission
     * @type {number}
     * @memberof PlayerMissionHistory
     */
    maxTimeAllowedInMinutes: number;
    /**
     * 
     * @type {PlayerMissionHistoryPlayersStats}
     * @memberof PlayerMissionHistory
     */
    playersStats: PlayerMissionHistoryPlayersStats;
    /**
     * The mission status
     * @type {string}
     * @memberof PlayerMissionHistory
     */
    status: PlayerMissionHistoryStatusEnum;
    /**
     * The maximum amount of rewards which can be earned when completing the stage.
     * @type {number}
     * @memberof PlayerMissionHistory
     */
    maxRewards: number;
    /**
     * The total amount of rewards earned by the player
     * @type {number}
     * @memberof PlayerMissionHistory
     */
    rewardsEarned: number;
    /**
     * An array describing the mission stages
     * @type {Array<PlayerMissionHistoryStage>}
     * @memberof PlayerMissionHistory
     */
    stages: Array<PlayerMissionHistoryStage>;
}

/**
* @export
* @enum {string}
*/
export enum PlayerMissionHistoryStatusEnum {
    COMPLETED = 'completed',
    FAILED = 'failed'
}

export function PlayerMissionHistoryFromJSON(json: any): PlayerMissionHistory {
    return PlayerMissionHistoryFromJSONTyped(json, false);
}

export function PlayerMissionHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerMissionHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionId': json['missionId'],
        'missionName': json['missionName'],
        'location': json['location'],
        'gps': json['gps'],
        'timeElapsedInMinutes': json['timeElapsedInMinutes'],
        'maxTimeAllowedInMinutes': json['maxTimeAllowedInMinutes'],
        'playersStats': PlayerMissionHistoryPlayersStatsFromJSON(json['playersStats']),
        'status': json['status'],
        'maxRewards': json['maxRewards'],
        'rewardsEarned': json['rewardsEarned'],
        'stages': ((json['stages'] as Array<any>).map(PlayerMissionHistoryStageFromJSON)),
    };
}

export function PlayerMissionHistoryToJSON(value?: PlayerMissionHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'missionId': value.missionId,
        'missionName': value.missionName,
        'location': value.location,
        'gps': value.gps,
        'timeElapsedInMinutes': value.timeElapsedInMinutes,
        'maxTimeAllowedInMinutes': value.maxTimeAllowedInMinutes,
        'playersStats': PlayerMissionHistoryPlayersStatsToJSON(value.playersStats),
        'status': value.status,
        'maxRewards': value.maxRewards,
        'rewardsEarned': value.rewardsEarned,
        'stages': ((value.stages as Array<any>).map(PlayerMissionHistoryStageToJSON)),
    };
}

