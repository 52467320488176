/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerMissionHistoryStage
 */
export interface PlayerMissionHistoryStage {
    /**
     * 
     * @type {number}
     * @memberof PlayerMissionHistoryStage
     */
    step: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerMissionHistoryStage
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerMissionHistoryStage
     */
    timeElapsedInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerMissionHistoryStage
     */
    maxRewards: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerMissionHistoryStage
     */
    rewardsEarned: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerMissionHistoryStage
     */
    status: string;
}

export function PlayerMissionHistoryStageFromJSON(json: any): PlayerMissionHistoryStage {
    return PlayerMissionHistoryStageFromJSONTyped(json, false);
}

export function PlayerMissionHistoryStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerMissionHistoryStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': json['step'],
        'name': json['name'],
        'timeElapsedInMinutes': json['timeElapsedInMinutes'],
        'maxRewards': json['maxRewards'],
        'rewardsEarned': json['rewardsEarned'],
        'status': json['status'],
    };
}

export function PlayerMissionHistoryStageToJSON(value?: PlayerMissionHistoryStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': value.step,
        'name': value.name,
        'timeElapsedInMinutes': value.timeElapsedInMinutes,
        'maxRewards': value.maxRewards,
        'rewardsEarned': value.rewardsEarned,
        'status': value.status,
    };
}

