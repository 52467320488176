<template>
  <div class="grid content-center justify-center text-center h-full">
    <div
      :class="loadingClass"
      class="after:animate-[spin_1.4s_linear_infinite] inline-block after:block after:contents-[''] after:rounded-full after:border-solid after:border-white after:border-y-white after:border-x-transparent"></div>
    <p v-if="!small" class="animate-pulse font-title">{{ t('loading') }}</p>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  interface Props {
    small?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    small: false,
  });

  const { t } = useI18n();
  const loadingClass = computed(() =>
    props.small
      ? 'w-[32px] h-[32px] after:w-[24px] after:h-[24px] after:m-[4px] after:border-[3px]'
      : 'w-[80px] h-[80px] after:w-[64px] after:h-[64px] after:m-[8px] after:border-[6px]'
  );
</script>
<i18n lang="yaml">
en:
  loading: loading
fr:
  loading: chargement
</i18n>
