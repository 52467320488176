/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents the mission statistics between players having completed the mission
 * @export
 * @interface PlayerMissionHistoryPlayersStats
 */
export interface PlayerMissionHistoryPlayersStats {
    /**
     * The average rewards earned by all the players on this mission (failed, completed or aborted)
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageRewardsEarned: number;
    /**
     * The average time spent by all the players on this mission (failed, completed or aborted)
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageTimeInMinutes: number;
    /**
     * The average rewards earned by the players having failed the mission
     * @type {string}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageRewardsEarnedWhenFailed: string;
    /**
     * The average rewards earned by the players having aborted the mission
     * @type {string}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageTimeInMinutesWhenAborted: string;
    /**
     * The average rewards earned by the players having completed the mission
     * @type {string}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageRewardsEarnedWhenCompleted: string;
    /**
     * The average time spent by all the players having completed this mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageTimeInMinutesWhenCompleted: number;
    /**
     * The average time spent by all the players having failed this mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageTimeInMinutesWhenFailed: number;
    /**
     * The average time spent by all the players having aborted this mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    averageRewardsEarnedWhenAborted: number;
    /**
     * The number of players having tried (started) the mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    playersHavingTried: number;
    /**
     * The number of players having completed the mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    playersHavingsCompleted: number;
    /**
     * The number of players having failed the mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    playersHavingsFailed: number;
    /**
     * The number of players having aborted the mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    playersHavingsAborted: number;
    /**
     * The best player callSign
     * @type {string}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    bestPlayerCallsign: string;
    /**
     * The best player reward amount
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    bestPlayerRewards: number;
    /**
     * The best player time spent on this mission
     * @type {number}
     * @memberof PlayerMissionHistoryPlayersStats
     */
    bestPlayerTime: number;
}

export function PlayerMissionHistoryPlayersStatsFromJSON(json: any): PlayerMissionHistoryPlayersStats {
    return PlayerMissionHistoryPlayersStatsFromJSONTyped(json, false);
}

export function PlayerMissionHistoryPlayersStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerMissionHistoryPlayersStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageRewardsEarned': json['averageRewardsEarned'],
        'averageTimeInMinutes': json['averageTimeInMinutes'],
        'averageRewardsEarnedWhenFailed': json['averageRewardsEarnedWhenFailed'],
        'averageTimeInMinutesWhenAborted': json['averageTimeInMinutesWhenAborted'],
        'averageRewardsEarnedWhenCompleted': json['averageRewardsEarnedWhenCompleted'],
        'averageTimeInMinutesWhenCompleted': json['averageTimeInMinutesWhenCompleted'],
        'averageTimeInMinutesWhenFailed': json['averageTimeInMinutesWhenFailed'],
        'averageRewardsEarnedWhenAborted': json['averageRewardsEarnedWhenAborted'],
        'playersHavingTried': json['playersHavingTried'],
        'playersHavingsCompleted': json['playersHavingsCompleted'],
        'playersHavingsFailed': json['playersHavingsFailed'],
        'playersHavingsAborted': json['playersHavingsAborted'],
        'bestPlayerCallsign': json['bestPlayerCallsign'],
        'bestPlayerRewards': json['bestPlayerRewards'],
        'bestPlayerTime': json['bestPlayerTime'],
    };
}

export function PlayerMissionHistoryPlayersStatsToJSON(value?: PlayerMissionHistoryPlayersStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'averageRewardsEarned': value.averageRewardsEarned,
        'averageTimeInMinutes': value.averageTimeInMinutes,
        'averageRewardsEarnedWhenFailed': value.averageRewardsEarnedWhenFailed,
        'averageTimeInMinutesWhenAborted': value.averageTimeInMinutesWhenAborted,
        'averageRewardsEarnedWhenCompleted': value.averageRewardsEarnedWhenCompleted,
        'averageTimeInMinutesWhenCompleted': value.averageTimeInMinutesWhenCompleted,
        'averageTimeInMinutesWhenFailed': value.averageTimeInMinutesWhenFailed,
        'averageRewardsEarnedWhenAborted': value.averageRewardsEarnedWhenAborted,
        'playersHavingTried': value.playersHavingTried,
        'playersHavingsCompleted': value.playersHavingsCompleted,
        'playersHavingsFailed': value.playersHavingsFailed,
        'playersHavingsAborted': value.playersHavingsAborted,
        'bestPlayerCallsign': value.bestPlayerCallsign,
        'bestPlayerRewards': value.bestPlayerRewards,
        'bestPlayerTime': value.bestPlayerTime,
    };
}

