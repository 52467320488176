/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerUpdateResult
 */
export interface PlayerUpdateResult {
    /**
     * The update status result
     * @type {string}
     * @memberof PlayerUpdateResult
     */
    status: PlayerUpdateResultStatusEnum;
    /**
     * Details about the update operation
     * @type {string}
     * @memberof PlayerUpdateResult
     */
    details: string;
}

/**
* @export
* @enum {string}
*/
export enum PlayerUpdateResultStatusEnum {
    COMPLETED = 'completed'
}

export function PlayerUpdateResultFromJSON(json: any): PlayerUpdateResult {
    return PlayerUpdateResultFromJSONTyped(json, false);
}

export function PlayerUpdateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerUpdateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'details': json['details'],
    };
}

export function PlayerUpdateResultToJSON(value?: PlayerUpdateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'details': value.details,
    };
}

