import { App } from 'vue';
import DomPurify from 'dompurify';

export default {
  install(app: App) {
    app.directive('html-safe', {
      mounted: function (el, binding) {
        el.innerHTML = DomPurify.sanitize(binding.value);
      },
      updated: function (el, binding) {
        el.innerHTML = DomPurify.sanitize(binding.value);
      },
    });
  },
};
