import { PlayerInformationPlayer, PlayersApi } from '@/generated-api';
import useSWRV from 'swrv';
import { useApiConfig } from '@/composables/ApiConfig';
import { usePlayer } from '@/composables/Player';
import { ApiSWRVKey } from './ApiSWRVKey';

export function usePlayerInfo() {
  const { configuration } = useApiConfig();
  const playersApi = new PlayersApi(configuration.value);
  const { player: currentPlayer } = usePlayer();

  const getPlayerInfo = async (): Promise<PlayerInformationPlayer> => {
    const { player } = await playersApi.playerInformation({
      playerId: currentPlayer.value.id,
    });
    return player;
  };

  return {
    getPlayerInfo: () => useSWRV(ApiSWRVKey.PLAYER_INFO, getPlayerInfo),
  };
}
