import { Component, defineAsyncComponent, toRefs, readonly, computed, watch, reactive } from 'vue';
import { useLocalStorage } from '@vueuse/core';
export enum STATES {
    BRIEFING = 'Briefing',
    LOGIN = 'Login',
    LOGGED_OFF = 'LoggedOff',
    BOARDING = 'Boarding',
    EARTH_TEST = 'EarthTest',
    DEBRIEF = 'Debrief',
    FORMATION = 'Formation',
    REPORT = 'Report',
}

export interface Meta {
    showNavbar?: boolean;
    showHeader?: boolean;
    mainNavbar?: boolean;
    disableAuth?: boolean;
}

interface PageComponent {
    name: string;
    component: Component;
    meta: Meta;
}

export const pageComponents: PageComponent[] = [
    {
        name: STATES.LOGIN,
        meta: { disableAuth: true },
        component: defineAsyncComponent(() => import('@/pages/LoginPage.vue')),
    },
    {
        name: STATES.LOGGED_OFF,
        meta: { disableAuth: true },
        component: defineAsyncComponent(() => import('@/pages/LoggedOffPage.vue')),
    },
    {
        name: STATES.BOARDING,
        meta: { mainNavbar: true, showNavbar: true, showHeader: true },
        component: defineAsyncComponent(() => import('@/pages/BoardingPage.vue')),
    },
    {
        name: STATES.BRIEFING,
        meta: { mainNavbar: true, showNavbar: true, showHeader: true },
        component: defineAsyncComponent(() => import('@/pages/BriefingPage.vue')),
    },
    {
        name: STATES.DEBRIEF,
        meta: {
            mainNavbar: true,
            showNavbar: true,
            showHeader: true,
        },
        component: defineAsyncComponent(() => import('@/pages/DebriefPage.vue')),
    },
    {
        name: STATES.REPORT,
        meta: {
            mainNavbar: false,
            showNavbar: false,
            disableAuth: true,
            showHeader: false,
        },
        component: defineAsyncComponent(() => import('@/pages/ReportPage.vue')),
    },
    {
        name: STATES.EARTH_TEST,
        meta: {
            mainNavbar: import.meta.env.DEV,
            showNavbar: true,
            showHeader: true,
        },
        component: defineAsyncComponent(() => import('@/pages/EarthTestPage.vue')),
    },
    {
        name: STATES.FORMATION,
        meta: {
            mainNavbar: false,
            showHeader: true,
        },
        component: defineAsyncComponent(() => import('@/pages/FormationPage.vue')),
    },
];

const state = reactive({
    current: useLocalStorage('currentState', STATES.LOGIN),
    titleOptions: {},
    subtitleOptions: {},
    firstConnection: useLocalStorage('firstConnection', true)
})

export const useState = () => {

    const currentPage = computed(() => {
        const isReportUrl = window.location.pathname === '/report';
        const pageComponent = pageComponents.find((p) => p.name === (isReportUrl ? STATES.REPORT : state.current));
        if (!pageComponent) {
            throw new Error(`No page component exists for state ${state.current}`);
        }
        return pageComponent;
    });

    return {
        ...toRefs(readonly(state)),
        setFirstConnection: (value: boolean) => {
            state.firstConnection = value;
        },
        currentPage,
        finishFormation: () => {
            state.firstConnection = false;
            state.current = STATES.BOARDING;
        },
        setSubtitleOptions: (options: Record<string, string>) => {
            state.subtitleOptions = options;
        },
        setTitleOptions: (options: Record<string, string>) => {
            state.titleOptions = options;
        },
        goToFormation: () => {
            state.current = STATES.FORMATION;
        },
        goToBoarding: () => {
            if (state.firstConnection) {
                state.current = STATES.FORMATION;
                return;
            }
            state.current = STATES.BOARDING;
        },
        goToLogin: () => {
            localStorage.clear();
            document.location.replace('');
        },
        goToLogOff: () => {
            state.current = STATES.LOGGED_OFF;
        },
        goToBriefing: () => {
            state.current = STATES.BRIEFING;
        },
        goToDebrief: () => {
            state.current = STATES.DEBRIEF;
        },
        reset: () => {
            localStorage.clear();
            state.current = STATES.LOGIN;
        }
    };
};

watch(
    () => state.current,
    (newVal, oldVal) => {
        if (import.meta.env.DEV) {
            console.log(`Changing state ${oldVal}=>${newVal}`);
        }
    },
    { immediate: true }
);

