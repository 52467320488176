/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerMissionHistory,
    PlayerMissionHistoryFromJSON,
    PlayerMissionHistoryFromJSONTyped,
    PlayerMissionHistoryToJSON,
} from './PlayerMissionHistory';

/**
 * 
 * @export
 * @interface PlayerMissionsHistory
 */
export interface PlayerMissionsHistory {
    /**
     * An array with all missions completed or failed by the player
     * @type {Array<PlayerMissionHistory>}
     * @memberof PlayerMissionsHistory
     */
    missions: Array<PlayerMissionHistory>;
}

export function PlayerMissionsHistoryFromJSON(json: any): PlayerMissionsHistory {
    return PlayerMissionsHistoryFromJSONTyped(json, false);
}

export function PlayerMissionsHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerMissionsHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missions': ((json['missions'] as Array<any>).map(PlayerMissionHistoryFromJSON)),
    };
}

export function PlayerMissionsHistoryToJSON(value?: PlayerMissionsHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'missions': ((value.missions as Array<any>).map(PlayerMissionHistoryToJSON)),
    };
}

