/* tslint:disable */
/* eslint-disable */
/**
 * ZeLab API
 * This API provided everything you need to create, update, delete objects like mission or player in the ZeLab context.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fr-ccoe@fr.clara.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RewardsBy
 */
export interface RewardsBy {
    /**
     * The name of the category/sub-category/service or application
     * @type {string}
     * @memberof RewardsBy
     */
    name: string;
    /**
     * The amount of rewards earned by the player
     * @type {number}
     * @memberof RewardsBy
     */
    rewards: number;
    /**
     * The maximum rewards that the player might have won
     * @type {number}
     * @memberof RewardsBy
     */
    maxRewards: number;
}

export function RewardsByFromJSON(json: any): RewardsBy {
    return RewardsByFromJSONTyped(json, false);
}

export function RewardsByFromJSONTyped(json: any, ignoreDiscriminator: boolean): RewardsBy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'rewards': json['rewards'],
        'maxRewards': json['maxRewards'],
    };
}

export function RewardsByToJSON(value?: RewardsBy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'rewards': value.rewards,
        'maxRewards': value.maxRewards,
    };
}

