import { Configuration, ConfigurationParameters } from '@/generated-api';
import { computed } from 'vue';
import { usePlayer } from './Player';

const defaultConfigOptions: ConfigurationParameters = {
  basePath: import.meta.env.VITE_BASE_URL_API,
};

export function useApiConfig() {
  const { isAuthenticated, jwt } = usePlayer();
  return {
    defaultConfigOptions,
    // not readonly the class as already all this fields in readonly
    configuration: computed(() => {
      if (!isAuthenticated) {
        throw new Error('Not Authorized');
      }
      if (!jwt) {
        throw new Error('Missing jwt token');
      }
      return new Configuration({
        ...defaultConfigOptions,
        headers: {
          Authorization: `Bearer ${jwt.value}`,
        },
      });
    }),
  };
}
